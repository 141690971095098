import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import "./assets/user.css";

const Locked = () => {
  return (
    <Fragment>
      <div className="lockedcontainer">
        <span>
          <FontAwesomeIcon icon={faLock} beat />
          <h5 className="locked">Restricted</h5>
        </span>
      </div>
    </Fragment>
  );
};

export default Locked;
