import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import "./assets/transactionhx.css";

class Barchart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: {
        labels: [
          "Week 49",
          "Week 50",
          "Week 51",
          "Week 52",
          "Week 01",
          "Week 02",
          "Week 03",
        ],
        datasets: [
          {
            label: "Total Credit",
            data: [0, 0, 0, 0, 2410, 0, 1000],
            backgroundColor: "rgba(75, 192, 192, 0.7)",
          },
          {
            label: "Total Debit",
            data: [0, 0, 0, 0, 50, 0, 0],
            backgroundColor: "rgba(255, 99, 132, 0.7)",
          },
        ],
      },
      chartOptions: {
        animation: {
          duration: 7000,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  }

  render() {
    return (
      <div>
        <h3 className="barcharth3">Weekly History</h3>
        <Bar data={this.state.chartData} options={this.state.chartOptions} />
      </div>
    );
  }
}

export default Barchart;
