import React, { Component, Fragment } from "react";
import "./assets/wallet.css";
import MainDetails from "./MainDetails";
import { toast } from "react-toastify";

class Wallet extends Component {
  activate = () => {
    toast.warn("error");

    setTimeout(() => {
      toast.info(
        "easyFlex requirements not met. Downgrade to personal or match minimum requirements"
      );
    }, 2000);
  };
  render() {
    return (
      <Fragment>
        <div className="gridcontainerp">
          <div className="boxes boxa">
            <MainDetails />
          </div>
          <div className="boxesp boxbp">
            <div className="containerbtn">
              <button className="btn btn-secondary btn-lg btn-p" disabled>
                Send Money
              </button>

              <button className="btn btn-secondary btn-lg btn-p" disabled>
                Swap currency
              </button>
            </div>
          </div>
          <div className="boxesp boxcp">
            <div className="container">
              <div className="borderline">
                <h5>Digital Assets</h5>
              </div>
              <p>Deposit or swap assets to begin</p>
            </div>
          </div>
          <div className="boxesp boxdp">
            <div className="container">
              <div className="borderline">
                <h5>Digital Desk</h5>
              </div>

              <p
                style={{
                  color: " rgba(0, 255, 0, 0.685)",
                  fontSize: "1.3rem",
                }}>
                Active
              </p>
              <button
                className="btn btn-lg btn-secondary"
                onClick={this.activate}>
                Finalize easyFlex
              </button>
              <div className="ddbalance">
                <h5>Digital desk balance</h5>
                <p>USD 500.00</p>
              </div>
              <div>
                <h5>Uncleared top-up</h5>
                <p className="dduncleared">USD 504.85</p>
              </div>
            </div>
          </div>
          <div className="boxesp boxep">
            <div className="container stakep">
              <div className="borderline">
                <h5>Earnings and Bonuses</h5>
              </div>

              <p>USD 0.00</p>
              <button className="btn btn-primary" disabled>
                Send
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Wallet;
