import React, { Component } from "react";
import "./assets/transactionhx.css";
import UserData from "./services/User";

class TransactionHistory extends Component {
  render() {
    const transactions = UserData.transactionhx;

    return (
      <div className="transaction-history">
        <h3 className="transactionhxh3">Transaction History</h3>
        <ul>
          {transactions.map((transaction, index) => (
            <li
              key={index}
              id="transactionhxbox"
              className={transaction.type.toLowerCase()}>
              <span className="date">{transaction.date}</span>
              <span className="type">{transaction.type}</span>
              <span className="amount">{transaction.amount}</span>
              <span className="bank">{transaction.bank}</span>
              <span className="sender">{transaction.sender}</span>
              <span className="account-number">
                {transaction.accountNumber}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default TransactionHistory;
