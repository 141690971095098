import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./assets/wallet.css";

import {
  Navigate,
  Route,
  Routes,
  redirect,
  useNavigate,
} from "react-router-dom";

const Initializepage = (props) => {
  const navigate = useNavigate();
  redirect = () => {
    navigate("/");
  };

  return (
    <Fragment>
      <div className="container">
        <div className="spinner">
          <FontAwesomeIcon icon={faSpinner} />
          <div className="errornote">
            <h5>Error</h5>
            <p>Something failed. Try again later or contact support</p>
            <button onClick={redirect}>Ok</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Initializepage;
