import React, { Component, Fragment } from "react";
import "./assets/wallet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
class StakeDetails extends Component {
  state = {
    user: {
      availablefunds: 0,
      activestakes: 0,
      weeklyroi: 0,
    },
    dropdown1: true,
    dropdown2: false,
    dropdown3: false,
  };

  notify = () => {
    toast("This option is not yet available to you");
  };
  notify2 = () => {
    toast("Balance is empty");
  };

  drop1 = () => {
    this.setState((prevState) => ({
      dropdown1: !prevState.dropdown1,
    }));
  };

  drop2 = () => {
    this.setState((prevState) => ({
      dropdown2: !prevState.dropdown2,
    }));
  };

  drop3 = () => {
    this.setState((prevState) => ({
      dropdown3: !prevState.dropdown3,
    }));
  };
  render() {
    const { availablefunds, activestakes, weeklyroi } = this.state.user;
    const { dropdown1, dropdown2, dropdown3 } = this.state;
    return (
      <Fragment>
        <div className="bonus stakedetails">
          <h4>Stake Data</h4>
          <div className="bonusdivider">
            <div className="bonusdivider2" onClick={this.drop1}>
              <h5>Active Stakes</h5>
              <i>
                {" "}
                <FontAwesomeIcon icon={faAngleDown} />
              </i>
            </div>

            {dropdown1 && (
              <p className="bonuspending">
                {activestakes > 0 ? { activestakes } : "Select plan to start"}
              </p>
            )}
          </div>
          <div className="bonusdivider">
            <div className="bonusdivider3" onClick={this.drop2}>
              <h5>Total Balance</h5>
              <i>
                {" "}
                <FontAwesomeIcon icon={faAngleDown} />
              </i>
            </div>

            {dropdown2 && (
              <p className="bonuspending">{`USD $${availablefunds}`} </p>
            )}
          </div>
          <div className="bonusdivider">
            <div className="bonusdivider4" onClick={this.drop3}>
              <h5>Weekly ROI</h5>
              <i>
                {" "}
                <FontAwesomeIcon icon={faAngleDown} />
              </i>
            </div>
            {dropdown3 && (
              <p className="bonuspending">{`USD $${weeklyroi}`} </p>
            )}
          </div>
          <div className="bonusdivide"></div>
          <div className="stakebuttons">
            <button onClick={this.notify} className="btn btn-lg btn-secondary">
              Add Money
            </button>
            <button onClick={this.notify2} className="btn btn-lg btn-secondary">
              Withdraw
            </button>
          </div>
          <div className="stakebuttonssm">
            <button onClick={this.notify} className="btn btn-sm btn-secondary">
              Add Money
            </button>
            <button onClick={this.notify2} className="btn btn-sm btn-secondary">
              Withdraw
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StakeDetails;
