import React, { Component, Fragment } from "react";
import { Line } from "react-chartjs-2";
import "./assets/wallet.css";

class WalletBalance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      balance: 5752609.85,
      chartData: {
        labels: [
          "Week 49",
          "Week 50",
          "Week 51",
          "Week 52",
          "Week 01",
          "Week 02",
          "Week 03",
        ],
        datasets: [
          {
            label: "Total Credit",
            data: [0, 0, 0, 2350, 0, 1000],
            fill: false,
            borderColor: "#0074cc",
            tension: 0.4,
          },
          {
            label: "Total Debit",
            data: [0, 0, 0, 50, 0, 1000],
            fill: false,
            borderColor: "#dc3545",
            tension: 0.4,
          },
        ],
      },
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Fragment>
        <h5 className="balancegraphh5">Balance Graph</h5>
        <div className="wallet-balance-container">
          <div className="chart-container">
            <Line
              data={this.state.chartData}
              options={{
                maintainAspectRatio: false,
                scales: {
                  x: {
                    grid: {
                      display: true,
                    },
                    display: false,
                  },
                  y: {
                    beginAtZero: true,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
            <div className="balance-overlay">
              <h2>Your Balance</h2>
              <span className="currency">$</span>
              <span className="balance">{this.state.balance.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default WalletBalance;
