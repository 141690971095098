import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

import "./App.css";
import Dashboard from "../src/components/Dashboard";
import NavBar1 from "./components/NavBar1";
import Wallet from "./components/Wallet";
import Home from "./components/Home";
import History1 from "./components/History1";
import User from "./components/User";
import Inbox from "./components/Inbox";
import Earnings from "./components/Earnings";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import MiniToggle from "./components/MiniToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Help from "./Help";
import { toast } from "react-toastify";
import UserData from "./components/services/User";
import Secured from "./components/Secured";
import Initializepage from "./components/Initializepage";
import Payments from "./components/Payments";

function App() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleMenu = () => {
    setIsVisible((prevVisibility) => !prevVisibility);
  };

  const style = isVisible ? { color: "red" } : { color: "initial" };

  useEffect(() => {
    authenticate();
  }, []);

  useEffect(() => {
    popup();
  }, []);

  const popup = () => {
    const auth = Cookies.get("auth");
    if (auth) {
      toast.info("Terminal login enabled");

      setTimeout(() => {
        toast.loading("Remote activation in progress");
      }, 3000);

      setTimeout(() => {
        function deleteCookies(name) {
          document.cookie =
            name + "=;expires=thu, 01 jan 1970 00:00:00 UTC;path=/;";
        }
        deleteCookies("auth");
        toast.error("Login failed. Wait until remote activation is completed.");
        return navigate("secured");
      }, 6000);

      setTimeout(() => {
        const screenWidth = window.innerWidth;
        if (screenWidth > 1023) {
        }
      }, 5000);
      return;
    }
  };

  const navigate = useNavigate();

  const authenticate = () => {
    const auth = Cookies.get("auth");

    if (!auth && window.location.pathname !== "/secured") {
      toast.error("Authentication error. Please log in");
      return navigate("secured");
    }
    const user = UserData.auth;
    if (auth !== user && window.location.pathname !== "/secured") {
      toast.error("Invalid Authentication");
      return navigate("secured");
    }
  };

  const auth = Cookies.get("auth");

  return (
    <div className="container1">
      <div className="togglebody" onClick={toggleMenu}>
        {auth && (
          <i style={style}>
            <FontAwesomeIcon
              style={{ width: "2rem", height: "2rem" }}
              icon={faBars}
            />
          </i>
        )}

        {isVisible && (
          <div className="minitoggler">
            <MiniToggle />
          </div>
        )}
      </div>
      <ToastContainer />

      <div className="row maingrid">
        <div className="col dash sdash" id="sdash">
          {auth && <Dashboard />}
        </div>
        <div className="col-6" id="dashcol">
          <div className="row snav">{auth && <NavBar1 />}</div>
          <div className="contentbox scontentarea">
            <Routes>
              <Route path="/" element={<Home />} />
              {auth && <Route path="wallet/*" element={<Wallet />} />}
              {auth && <Route path="history" element={<History1 />} />}
              {auth && <Route path="desk" element={<Payments />} />}

              {auth && <Route path="user" element={<User />} />}
              {auth && <Route path="inbox" element={<Inbox />} />}
              {auth && <Route path="earnings" element={<Earnings />} />}
              {auth && <Route path="help" element={<Help />} />}

              {auth && <Route path="initialize" element={<Initializepage />} />}

              {!auth && <Route path="secured" element={<Secured />} />}

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
