import React, { Component, Fragment } from "react";
import "./assets/wallet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBtc } from "@fortawesome/free-brands-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
class DigitalAssets extends Component {
  state = {
    bitcoin: 0.0,
    usdt: 0.0,
    isVisible: false,
    isVisible2: false,
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };

  toggleMenu2 = () => {
    this.setState((prevState) => ({
      isVisible2: !prevState.isVisible2,
    }));
  };
  render() {
    const { bitcoin, usdt, isVisible, isVisible2 } = this.state;

    return (
      <Fragment>
        <div className="dassetsbox">
          <h5>Digital Assets</h5>
          <div className="dassetbtc">
            <div className="dassetbox2" onClick={this.toggleMenu}>
              <i>
                <FontAwesomeIcon icon={faBtc} bounce />
              </i>
              <span>Bitcoin</span>
            </div>

            <i className="arrow" onClick={this.toggleMenu}>
              {" "}
              <FontAwesomeIcon icon={faCaretDown} />
            </i>
          </div>

          {isVisible && (
            <div className="btcbalance">
              <div className="btcbox">{` $${bitcoin}`}</div>
            </div>
          )}

          <div className="dassetusdt" onClick={this.toggleMenu2}>
            <div>
              <i>
                <FontAwesomeIcon icon={faDollarSign} bounce />
              </i>

              <span>USDT</span>
            </div>

            <i className="arrow" onClick={this.toggleMenu2}>
              <FontAwesomeIcon icon={faCaretDown} />
            </i>
          </div>
          {isVisible2 && (
            <div className="btcbalance">
              <div className="btcbox">{` $${usdt}`}</div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default DigitalAssets;
