const poolsData = [
  {
    poolname: "BTC-USDT",
    poolroi: 12,
    poolsupply: "N/A",
    pooldemand: "N/A",
    activated: true,
    image: require("../utils/images/btc.png"),
  },
  {
    poolname: "ARCON",
    poolroi: 34,
    poolsupply: "N/A",
    pooldemand: "N/A",
    activated: true,
    image: require("../utils/images/Arcon.png"),
  },
  {
    poolname: "AltCycle",
    poolroi: 18,
    poolsupply: "N/A",
    pooldemand: "N/A",
    activated: false,
    image: require("../utils/images/altcoins.png"),
  },
  {
    poolname: "NFT-HUB",
    poolroi: 0.8,
    poolsupply: "N/A",
    pooldemand: "N/A",
    activated: false,
    image: require("../utils/images/nft.png"),
  },
  {
    poolname: "COSMOS",
    poolroi: 16,
    poolsupply: "N/A",
    pooldemand: "N/A",
    activated: false,
    image: require("../utils/images/cosmos.jpg"),
  },
  {
    poolname: "VIEU",
    poolroi: 31,
    poolsupply: "N/A",
    pooldemand: "N/A",
    activated: false,
    image: require("../utils/images/vuie.jpg"),
  },
];

export default poolsData;
