const UserData = {
  name: "Brijesh Patel",
  region: "Hong Kong",
  balance: 5752969.85,
  auth: "jwt099PK_wbp07",
  wallet: "B100170502",
  password: "0021WVSS0223",
  transactionhx: [
    {
      date: "January 25, 2024",
      type: "Credit",
      amount: "$500",
      bank: "",
      sender: "Digital Top-up",
      accountNumber: "",
    },
    {
      date: "January 25, 2024",
      type: "Credit",
      amount: "$500",
      bank: "",
      sender: "Digital Top-up",
      accountNumber: "",
    },
    {
      date: "January 11, 2024",
      type: "Credit",
      amount: "$1060",
      bank: "BOA",
      sender: "Allianz Capital",
      accountNumber: "****6081",
    },
    {
      date: "January 03, 2024",
      type: "Debit",
      amount: "$50",
      bank: "NOVA",
      sender: "",
      accountNumber: "",
    },
    {
      date: "January 03, 2024",
      type: "Credit",
      amount: "$600",
      bank: "BOA",
      sender: "Allianz Capital",
      accountNumber: "****6081",
    },
    {
      date: "January 03, 2024",
      type: "Credit",
      amount: "$350",
      bank: "BOA",
      sender: "Allianz Capital",
      accountNumber: "****6081",
    },
    {
      date: "January 03, 2024",
      type: "Credit",
      amount: "$400",
      bank: "BOA",
      sender: "Allianz Capital",
      accountNumber: "****6081",
    },
    {
      date: "November 20, 2023",
      type: "Credit",
      amount: "$100,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "November 18, 2023",
      type: "Credit",
      amount: "$41,311",
      bank: "HSBC",
      sender: "M&P Inc",
      accountNumber: "****0190",
    },
    {
      date: "November 18, 2023",
      type: "Credit",
      amount: "$50,000",
      bank: "HSBC",
      sender: "M&P Inc",
      accountNumber: "****0190",
    },
    {
      date: "November 18, 2023",
      type: "Credit",
      amount: "$250,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "November 15, 2023",
      type: "Credit",
      amount: "$100,000",
      bank: "HSBC",
      sender: "M&P Inc",
      accountNumber: "****0190",
    },
    {
      date: "November 15, 2023",
      type: "Credit",
      amount: "$150,000",
      bank: "HSBC",
      sender: "M&P Inc",
      accountNumber: "****0190",
    },
    {
      date: "November 15, 2023",
      type: "Credit",
      amount: "$180,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "November 13, 2023",
      type: "Credit",
      amount: "$320,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "November 9, 2023",
      type: "Credit",
      amount: "$250,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "November 8, 2023",
      type: "Credit",
      amount: "$300,000",
      bank: "HSBC",
      sender: "M&P Inc",
      accountNumber: "****0190",
    },
    {
      date: "November 8, 2023",
      type: "Credit",
      amount: "$50,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "November 5, 2023",
      type: "Credit",
      amount: "$50,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "November 5, 2023",
      type: "Credit",
      amount: "$300,000",
      bank: "HSBC",
      sender: "M&P Inc",
      accountNumber: "****0190",
    },
    {
      date: "November 5, 2023",
      type: "Credit",
      amount: "$500,000",
      bank: "HSBC",
      sender: "M&P Inc",
      accountNumber: "****0190",
    },
    {
      date: "November 1, 2023",
      type: "Credit",
      amount: "$500,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "October 29, 2023",
      type: "Credit",
      amount: "$212,789",
      bank: "SunTrust",
      sender: "Linda Derouen",
      accountNumber: "****8011",
    },
    {
      date: "October 28, 2023",
      type: "Credit",
      amount: "$200,000",
      bank: "SunTrust",
      sender: "Linda Derouen",
      accountNumber: "****8011",
    },
    {
      date: "October 22, 2023",
      type: "Credit",
      amount: "$287,000",
      bank: "SunTrust",
      sender: "Linda Derouen",
      accountNumber: "****8011",
    },
    {
      date: "October 16, 2023",
      type: "Credit",
      amount: "$400,000",
      bank: "SunTrust",
      sender: "Linda Derouen",
      accountNumber: "****8011",
    },
    {
      date: "October 13, 2023",
      type: "Credit",
      amount: "$300,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "October 10, 2023",
      type: "Credit",
      amount: "$30,000",
      bank: "Chase",
      sender: "WVSS Inc",
      accountNumber: "****0169",
    },
    {
      date: "October 09, 2023",
      type: "Credit",
      amount: "$8,900",
      bank: "Chase",
      sender: "WVSS Inc",
      accountNumber: "****0169",
    },
    {
      date: "October 03, 2023",
      type: "Credit",
      amount: "$285,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "October 01, 2023",
      type: "Credit",
      amount: "$100,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "September 28, 2023",
      type: "Credit",
      amount: "$250,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "September 26, 2023",
      type: "Credit",
      amount: "$300,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "September 20, 2023",
      type: "Credit",
      amount: "$100,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "September 18, 2023",
      type: "Credit",
      amount: "$150,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
    {
      date: "November 18, 2023",
      type: "Credit",
      amount: "$50,000",
      bank: "HSBC",
      sender: "M&P Inc",
      accountNumber: "****0190",
    },
    {
      date: "November 18, 2023",
      type: "Credit",
      amount: "$41,311",
      bank: "HSBC",
      sender: "M&P Inc",
      accountNumber: "****0190",
    },
    {
      date: "November 18, 2023",
      type: "Credit",
      amount: "$250,000",
      bank: "BOA",
      sender: "M&P Ventures",
      accountNumber: "****3171",
    },
  ],
};

export default UserData;
