import React, { Fragment } from "react";
import PieChart from "./PieChart";
import Barchart from "./Barchart";
import TransactionHistory from "./TransactionHx";
import "../App.css";

const Home = () => {
  return (
    <Fragment>
      <div className="homegrid">
        <div className="homebox1 home0" id="piebalance">
          <div className="chartcontainer">
            <PieChart />
            <div id="balancenote"></div>
          </div>
        </div>
        <div className="homebox2 home0">
          <div className="dash2">
            <Barchart />
          </div>
        </div>{" "}
        <div className="homebox3 dash3 home0">
          <TransactionHistory />
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
