import React, { Fragment } from "react";
import "./components/assets/help.css";

const Help = (props) => {
  return (
    <Fragment>
      <div className="helplayout">
        <div className="helpboxa helpbox">
          <h5>
            <b>General Rules to Follow</b>
          </h5>
          <br />
          <br />
          <p>
            <b>Protect Your Password:</b> Never share your wallet password with
            anyone. <span className="nova">NOVA</span> will never ask for your
            password via email or other non-secure channels. <br /> <br />
            <b>Beware of Phishing Attempts:</b> Be cautious of emails, messages,
            or websites that request sensitive information. Always verify the
            authenticity of communications before providing any personal
            details. <br />
            <br />
            <b>Use Two-Factor Authentication (2FA):</b> Enable 2FA for an
            additional layer of security. This ensures that even if your
            password is compromised, unauthorized access is prevented. <br />
            <br />
            <b>Keep Software Updated:</b> Regularly update your{" "}
            <span className="nova">NOVA</span> app and device operating system
            to benefit from the latest security patches.
          </p>

          <article>
            <h5>General Security Tips</h5> <br /> 1. Be Skeptical of Unsolicited
            Communication: Be cautious of unexpected emails, messages, or calls
            asking for personal information. Legitimate entities will not
            request sensitive data without proper authentication. <br /> <br />
            2. Check Website URLs: Ensure that the website address begins with
            "https://" and matches the official{" "}
            <span className="nova">NOVA</span> domain. Avoid clicking on links
            from untrusted sources. <br /> <br />
            3. Monitor Your Account Regularly: Review your transaction history
            regularly to identify any unauthorized activities. Report any
            discrepancies or suspicious transactions to{" "}
            <span className="nova">NOVA</span> support immediately. <br />
            <br />
            4. Educate Yourself: Stay informed about common scams and phishing
            techniques. Be aware of the latest security threats to better
            protect yourself and your wallet. <br />
            <br />
            5. Contact Customer Support: If you have any concerns or doubts
            about the security of your <span className="nova">NOVA</span>{" "}
            account, contact customer support directly through the official
            channels provided on the website or app.
          </article>
        </div>
        <div className="helpboxb helpbox">
          <h5>
            <b>KYC Requirements based on US Regulations</b>
          </h5>{" "}
          <p>
            <span className="nova">NOVA</span> adheres to US regulations,
            including KYC requirements, to ensure the security of your financial
            transactions.br KYC helps prevent identity theft, fraud, and money
            laundering. <br />
            <br />
            <b>Identity Verification:</b> Users are required to provide valid
            identification documents to verify their identity. This includes a
            government-issued ID, proof of address, and other relevant
            documents. <br />
            <br /> <b>Transaction Monitoring:</b>{" "}
            <span className="nova">NOVA</span> monitors transactions for
            suspicious activities, ensuring compliance with regulatory standards
            and protecting users from financial crimes.
          </p>
        </div>
        <div className="helpbox helpboxc">
          <div className="helplink">
            <h3>Help Desk</h3>
            <h5>
              <a href="mailto:support@novafluxe.com">
                How to make deposits and withdrawals
              </a>
            </h5>
            <h5>
              <a href="mailto:support@novafluxe.com">
                Making deposits via crypto
              </a>
            </h5>
            <h5>
              <a href="mailto:support@novafluxe.com">
                How to make withdrawals via crypto
              </a>
            </h5>
            <h5>
              <a href="mailto:support@novafluxe.com">
                {" "}
                Deposits and withdrawal delay or reversal
              </a>
            </h5>
            <h5>
              <a href="mailto:support@novafluxe.com">
                {" "}
                Hacked wallet or unauthorized withdrawals
              </a>
            </h5>
            <h5>
              <a href="mailto:support@novafluxe.com">
                {" "}
                Regen private key and deauthorize browser
              </a>
            </h5>
            <h5>
              <a href="mailto:support@novafluxe.com">
                Kyc requirements and activation
              </a>
            </h5>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Help;
