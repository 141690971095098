import React, { Component } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import "./assets/earnings.css";

class MarketData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketData: null,
      chartData: null,
    };
  }

  componentDidMount() {
    this.fetchMarketData();
  }

  fetchMarketData = () => {
    axios
      .get("https://blockchain.info/ticker")
      .then((response) => {
        const marketData = response.data;
        this.setState({ marketData });
        this.prepareChartData(marketData);
      })
      .catch((error) => {
        console.error("Error fetching market data:", error);
      });
  };

  prepareChartData = (marketData) => {
    const currencies = Object.keys(marketData);
    const chartData = {
      labels: currencies,
      datasets: [
        {
          label: "Exchange Rate",
          data: currencies.map((currency) => marketData[currency].last),
          fill: false,
          borderColor: "rgba(255, 99, 132, 0.7)",
          tension: 0.4,
        },
      ],
    };

    this.setState({ chartData });
  };

  render() {
    const { chartData } = this.state;

    return (
      <div
        className="earningsmarketchart"
        style={{
          padding: "2rem",
          borderRadius: "10px",
        }}>
        <h2 style={{ color: "rgba(28, 36, 52, 0.76)" }}>Market Data Chart</h2>
        {chartData ? (
          <Line
            data={chartData}
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  grid: {
                    display: true,
                  },
                  display: true,
                  title: {
                    display: false,
                    text: "Currency",
                    color: "rgba(28, 36, 52, 0.76);",
                  },
                },
                y: {
                  beginAtZero: true,
                  display: true,
                  title: {
                    display: false,
                    text: "Exchange Rate",
                    color: "rgba(28, 36, 52, 0.76);",
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        ) : (
          <p style={{ color: "#fff" }}>Loading market data...</p>
        )}
      </div>
    );
  }
}

export default MarketData;
