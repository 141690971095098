import React, { Fragment } from "react";

const MainDetails = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="maindetails1">
          <article>
            <p>Brijesh Patel</p>
            <p>001082231802</p>
            <p>Novafluxe MicroFinance</p>
          </article>
        </div>

        <div className="maindetails1">
          <article>
            <h5>Corrospondent Bank Details</h5>
            <p>Bank of America</p>
            <p>BOFAUS3N</p>
          </article>
        </div>
      </div>
    </Fragment>
  );
};

export default MainDetails;
