import React, { Component, Fragment } from "react";
import Pool from "./Pool";
import poolsData from "./PoolData";

class Pools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pools: poolsData,
    };
  }

  render() {
    const { pools } = this.state;
    return (
      <Fragment>
        <div className="poolscontainer">
          <h4>Stake Pools</h4>
          <ul>
            {pools.map((pool, index) => (
              <li key={index}>
                <Pool
                  poolname={pool.poolname}
                  poolroi={pool.poolroi}
                  poolsupply={pool.pooldemand}
                  pooldemand={pool.poolsupply}
                  activated={pool.activated}
                  image={pool.image}
                />
              </li>
            ))}
          </ul>
        </div>
      </Fragment>
    );
  }
}

export default Pools;
