import React, { Component, Fragment } from "react";
import "./assets/aside.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLifeRing } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

class MessageBadge extends Component {
  state = {
    notifications: 27,
  };

  notify = () => {
    toast("Activate to unlock");
  };

  render() {
    const style = { padding: "0.4rem 1rem" };
    const { notifications } = this.state;

    return (
      <Fragment>
        <ul className="listgroup" id="asidelist2">
          <li
            style={style}
            className="menu-btn list-group-item"
            onClick={this.notify}>
            <Link className="link">
              <FontAwesomeIcon className="icon" icon={faEnvelope} />
              Inbox
              {notifications === 0 ? (
                ""
              ) : (
                <span className="redbadge">{notifications}</span>
              )}
            </Link>
          </li>
          <li
            style={style}
            className="menu-btn list-group-item"
            onClick={this.notify}>
            <Link className="link">
              <FontAwesomeIcon className="icon" icon={faLifeRing} />
              24/7 Support
            </Link>
          </li>
        </ul>
        <span className="copyright">
          © 2023 Wallet IO. All rights reserved.
        </span>
      </Fragment>
    );
  }
}

export default MessageBadge;
