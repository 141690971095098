import React, { Fragment, useEffect } from "react";
import { toast } from "react-toastify";
import "./assets/wallet.css";
import { Link, useNavigate } from "react-router-dom";

const Initialize = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    toast.info(
      `You have $3300.00 available for immediate refund. Click Deactivate to start account deletion and funds reversal`
    );
    setTimeout(() => {
      toast.warn("Error!");
    }, 5000);

    setTimeout(() => {
      toast.warn("Something failed!");
    }, 6000);

    setTimeout(() => {
      navigate("wallet");
    }, 6500);

    setTimeout(() => {
      toast.info(
        "A crash report has been generated and dispatched to our technical team"
      );
    }, 7500);
  };

  return (
    <Fragment>
      <div className="container">
        <button
          onClick={handleClick}
          className="btn btn-lg btn-primary"
          disabled>
          Initialize Wallet
        </button>

        <a
          className="btn btn-lg btn-primary deactivate"
          href="mailto:deactivate@novafluxe.com">
          Deactivate Wallet
        </a>
      </div>
    </Fragment>
  );
};

export default Initialize;
