import React, { Component, Fragment } from "react";
import "./assets/user.css";
import PublicInfo from "./PublicInfo";
import Profile from "./Profile";
import Locked from "./Locked";
import Initialize from "./Initialize";

class User extends Component {
  render() {
    return (
      <Fragment>
        <div className="userprofile">
          <div className="userinfo1">
            <PublicInfo />
          </div>
          <div className="userinfo2">
            <Profile />
          </div>
          <div className="userinfo3">
            <Initialize />
          </div>
          <div className="userinfo4">
            <Locked />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default User;
