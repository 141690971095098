import React from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

const PieChart = () => {
  const bankBalance = 5752969.85;

  const data = {
    labels: ["Balance", "Remaining"],
    datasets: [
      {
        data: [bankBalance, 10000 - bankBalance],
        backgroundColor: ["#0074cc", "#ff4d4d"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "80%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      center: {
        text: `USD ${bankBalance.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        color: " rgba(0, 0, 255, 0.659)",
        fontStyle: "normal",
        minFontSize: 20,
      },
    },
  };

  const chartSize = {
    width: "250rem",
    height: "250rem",
  };

  const textCenter = {
    id: "textCenter",
    beforeDraw(chart) {
      const { ctx } = chart;
      const chartArea = chart.chartArea;

      ctx.save();

      // Center the text in the middle of the doughnut chart
      const textX = (chartArea.left + chartArea.right) / 2;
      const textY = (chartArea.top + chartArea.bottom) / 2;

      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.font = `${options.elements.center.fontStyle} ${options.elements.center.minFontSize}px sans-serif`;
      ctx.fillStyle = options.elements.center.color;

      // Draw the text
      ctx.fillText(options.elements.center.text, textX, textY);

      ctx.restore();
    },
  };

  return (
    <div>
      <Doughnut
        data={data}
        options={options}
        plugins={[textCenter]}
        {...chartSize}
      />
    </div>
  );
};

export default PieChart;
