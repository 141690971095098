import React, { Component, Fragment } from "react";
import "../components/assets/secured.css";
import UserData from "./services/User";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

class Secured extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        wallet: "",
        password: "",
      },
    };
  }
  setCookie(name, value, hours) {
    const expiration = new Date();
    expiration.setTime(expiration.getTime() + hours * 60 * 60 * 1000);
    const cookieString = `${name}=${value};expires=${expiration.toUTCString()};path=/`;
    document.cookie = cookieString;
  }

  handleChange = (e) => {
    const account = { ...this.state.account };
    account[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ account });
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });

    const { wallet, password } = this.state.account;
    if (wallet === UserData.wallet && password === UserData.password) {
      this.setCookie("auth", "jwt099PK_wbp07", 1);
      this.setState({ isLoading: false });
      return (window.location.href = "../");
    } else {
      toast.error("Invalid details. Try again");
    }
  };

  render() {
    const auth = Cookies.get("auth");
    return (
      <Fragment>
        {!auth && (
          <div className="securedlayout">
            <h2>Special Login Access</h2>
            <p>
              We've detected your attempt to login via special permissions.
              Please authenticate to continue
            </p>
            <div className="securedforms">
              <form onSubmit={this.handleSubmit}>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">
                    Wallet ID
                  </label>
                  <input
                    value={this.state.account.wallet}
                    onChange={this.handleChange}
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    name="wallet"
                  />
                  <div id="emailHelp" className="form-text">
                    We'll never share your ID with anyone else.
                  </div>
                </div>
                <div className="mb-3">
                  <label for="exampleInputPassword1" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    value={this.state.account.password}
                    name="password"
                    onChange={this.handleChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary">
                  {this.state.isLoading ? (
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Secured;
