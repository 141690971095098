import React, { Component, Fragment } from "react";
import Aside from "./Aside";
import "./assets/Dashboard.css";
import MessageBadge from "./MessageBadge";

class Dashboard extends Component {
  render() {
    return (
      <Fragment>
        <div className="container1">
          <div className="logo">
            <h1>NOVA</h1>
          </div>
          <Aside />
        </div>
        <div className="seperator">
          <MessageBadge />
        </div>
      </Fragment>
    );
  }
}

export default Dashboard;
