import React, { Component, Fragment } from "react";
import "./assets/wallet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

class Bonus extends Component {
  state = {
    user: {
      available: 0,
      pending: 3250,
      claimed: 0,
    },
    dropdown1: false,
    dropdown2: false,
    dropdown3: false,
  };

  drop1 = () => {
    this.setState((prevState) => ({
      dropdown1: !prevState.dropdown1,
    }));
  };

  drop2 = () => {
    this.setState((prevState) => ({
      dropdown2: !prevState.dropdown2,
    }));
  };

  drop3 = () => {
    this.setState((prevState) => ({
      dropdown3: !prevState.dropdown3,
    }));
  };
  render() {
    const { available, pending, claimed } = this.state.user;
    const { dropdown1, dropdown2, dropdown3 } = this.state;
    return (
      <Fragment>
        <div className="bonus">
          <h4>Bonuses</h4>
          <div className="bonusdivider">
            <div className="bonusdivider2" onClick={this.drop1}>
              <h5>Pending</h5>
              <i>
                {" "}
                <FontAwesomeIcon icon={faAngleDown} />
              </i>
            </div>

            {dropdown1 && <p className="bonuspending">{`USD $${pending}`} </p>}
          </div>
          <div className="bonusdivider">
            <div className="bonusdivider3" onClick={this.drop2}>
              <h5>Available</h5>
              <i>
                {" "}
                <FontAwesomeIcon icon={faAngleDown} />
              </i>
            </div>

            {dropdown2 && (
              <p className="bonuspending">{`USD $${available}`} </p>
            )}
          </div>
          <div className="bonusdivider">
            <div className="bonusdivider4" onClick={this.drop3}>
              <h5>Claimed</h5>
              <i>
                {" "}
                <FontAwesomeIcon icon={faAngleDown} />
              </i>
            </div>
            {dropdown3 && <p className="bonuspending">{`USD $${claimed}`} </p>}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Bonus;
