import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "./assets/nameplate.css";

const NamePlate = (props) => {
  return (
    <Fragment>
      <div className="nameplate">
        <span>
          <FontAwesomeIcon className="icon" icon={faUser} />
          {props.name}
        </span>
      </div>
    </Fragment>
  );
};

export default NamePlate;
