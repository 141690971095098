import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./assets/profilecard.css";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faGears,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

const ProfileCard = (props) => {
  const notify = () => toast("Not allowed");
  function deleteCookies(name) {
    document.cookie = name + "=;expires=thu, 01 jan 1970 00:00:00 UTC;path=/;";
  }

  const logout = () => {
    deleteCookies("auth");
    return (window.location.href = "https://wvsslegal.com/clientLogin.html");
  };
  return (
    <Fragment>
      <div className=" profilecard m-5">
        <Link to="user" className="linkcard">
          <FontAwesomeIcon className="icon" icon={faUser} />
          Profile
        </Link>
        <Link className="linkcard" onClick={notify}>
          <FontAwesomeIcon className="icon" icon={faGears} />
          Settings
        </Link>
        <Link className="linkcard logout" onClick={logout}>
          <FontAwesomeIcon className="icon" icon={faRightFromBracket} />
          Logout
        </Link>
      </div>
    </Fragment>
  );
};

export default ProfileCard;
