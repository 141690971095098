import React, { Fragment } from "react";
import Locked from "./Locked";

const Profile = () => {
  return (
    <Fragment>
      <div className="profilebox">
        <div className="details1">
          <Locked />{" "}
        </div>
      </div>
    </Fragment>
  );
};

export default Profile;
