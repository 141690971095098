import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";

class Balance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: "5,752,969.85",
    };
  }

  notify = () => {
    toast("Initialize Wallet to Unlock");
  };

  render() {
    return (
      <Fragment>
        <div className="fiatcard">
          <div className="fiatbalancecard">
            <div className="newcard">
              <div className="balanceamount">
                <h5>Balance</h5>
                <p>{`$${this.state.balance}`}</p>
              </div>
              <div className="balancestatus">
                <h5>Status</h5>
                <p>Active</p>
              </div>
            </div>
          </div>
          <button onClick={this.notify} className="send-button">
            Payments
          </button>
        </div>
      </Fragment>
    );
  }
}
export default Balance;
