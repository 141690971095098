import React, { Component, Fragment } from "react";
import "./assets/history.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

class History1 extends Component {
  state = {
    dropdown1: true,
    dropdown2: true,
    dropdown3: true,
    dropdown4: true,
  };

  drop1 = () => {
    this.setState((prevState) => ({
      dropdown1: !prevState.dropdown1,
    }));
  };

  drop2 = () => {
    this.setState((prevState) => ({
      dropdown2: !prevState.dropdown2,
    }));
  };

  drop3 = () => {
    this.setState((prevState) => ({
      dropdown3: !prevState.dropdown3,
    }));
  };
  drop4 = () => {
    this.setState((prevState) => ({
      dropdown4: !prevState.dropdown4,
    }));
  };
  render() {
    const { dropdown1, dropdown2, dropdown3, dropdown4 } = this.state;
    return (
      <Fragment>
        <div className="history">
          <div className="historybox1 historybox" id="">
            <div className="historydrop1" onClick={this.drop1}>
              <h4>Event History</h4>
              <i>
                <FontAwesomeIcon icon={faAngleDown} />
              </i>
            </div>

            {dropdown1 && (
              <div className="dropmsg1">
                <p>Event History unavailable in restricted mode</p>
              </div>
            )}
          </div>
          <div className="historybox2 historybox" id="">
            <div className="historydrop2" onClick={this.drop2}>
              <h4>Legal History</h4>
              <i>
                <FontAwesomeIcon icon={faAngleDown} />
              </i>
            </div>

            {dropdown2 && (
              <div className="dropmsg1">
                <p>Legal History unavailable in restricted mode</p>
              </div>
            )}
          </div>
          <div className="historybox3 historybox" id="">
            <div className="historydrop3" onClick={this.drop3}>
              <h4>Invoices, Receipts and Package Bill History</h4>
              <i>
                <FontAwesomeIcon icon={faAngleDown} />
              </i>
            </div>

            {dropdown3 && (
              <div className="dropmsg1">
                <p>
                  Package Bill Payment History, Invoices and Receipts
                  unavailable in restricted mode
                </p>
              </div>
            )}
          </div>

          <div className="historybox4 historybox" id="">
            <div className="historydrop4" onClick={this.drop4}>
              <h4>Financial and Tax Documentations</h4>
              <i>
                <FontAwesomeIcon icon={faAngleDown} />
              </i>
            </div>

            {dropdown4 && (
              <div className="dropmsg1">
                <p>Tax Documentations unavailable in restricted mode</p>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default History1;
