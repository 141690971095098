import React, { Fragment } from "react";
import UserData from "./services/User";

const PublicInfo = (props) => {
  const { name, wallet } = UserData;
  return (
    <Fragment>
      <div className="publicinfobox">
        <div className="infodetails">
          <p>
            <b>Name: </b> {name}
          </p>
          <p>
            <b>Gender:</b> Male
          </p>
          <p>
            <b>Region: </b>Hong Kong
          </p>
          <p>
            <b>Wallet ID:</b> {wallet}
          </p>
          <p>
            <b>Wallet Status</b> pending activation
          </p>
          <p>
            <b>Reference No</b> A_10589016
          </p>
          <p>
            <b>Package Type:</b> Asset Repatriation
          </p>
          <p>
            <b>Insurance Policy:</b> JLTS Community Trust
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default PublicInfo;
