import React, { Component, Fragment } from "react";

class StakeData extends Component {
  render() {
    return (
      <Fragment>
        <div className="stakecard">
          <div className="stakecardinner">
            <div className="stakeinvestment">
              <h5>Assets</h5>
              <p>Deposit assets to begin</p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default StakeData;
