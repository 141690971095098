import React from "react";
import "./assets/wallet.css";

const StakedAsset = (props) => {
  return (
    <div className="stakedassets">
      <h5>Staked Assets</h5>
      <p>There are currently no staked assets</p>
    </div>
  );
};

export default StakedAsset;
