import React, { Component, Fragment } from "react";
import "./assets/earnings.css";
import StakeData from "./StakeData";
import MarketData from "./MarketData";
import Pools from "./Pools";
import StakeDetails from "./StakeDetails";

class Earnings extends Component {
  render() {
    return (
      <Fragment>
        <div className="earningschart">
          <div className="earningsbox earningsboxa">
            <StakeData />
          </div>
          <div className="earningsbox earningsboxb">
            <MarketData />
          </div>
          <div className="earningsbox earningsboxc">
            <Pools />
          </div>
          <div className="earningsbox earningsboxd">
            <StakeDetails />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Earnings;
