import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopwatch20 } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "./assets/earnings.css";
import "../utils/images/Arcon.png";

const Pool = (props) => {
  const activated = (isActive) => {
    if (isActive) {
      return (
        <button onClick={notify} className="btn btn-lg btn-secondary">
          Start
        </button>
      );
    } else {
      return (
        <button onClick={notify} className="btn btn-lg btn-tertiary">
          <FontAwesomeIcon icon={faStopwatch20} spin />
        </button>
      );
    }
  };

  const notify = () => {
    toast("Not available in restricted mode");
  };

  const imgurl = props.image;
  const h5style =
    props.poolroi > 25
      ? {
          color: "rgba(38, 83, 24, 0.8)",
          backgroundColor: "rgba(123, 241, 87, 0.16)",
        }
      : {
          color: "rgba(218, 51, 51, 0.8)",
          backgroundColor: "rgba(218, 51, 51, 0.16)",
        };

  return (
    <Fragment>
      <div className="poolbody">
        <div className="poolimg poolbox">
          <div className="poolimgdiv">
            <img src={imgurl} alt="" />
          </div>
        </div>
        <div className="poolname poolbox">
          <h5>{props.poolname}</h5>
        </div>
        <div className="poolroi poolbox" style={h5style}>
          <h5>
            {props.poolroi}
            <b>%</b>
          </h5>
        </div>
        <div className="poolsupply poolbox">
          <h5>{props.poolsupply}</h5>
        </div>
        <div className="pooldemand poolbox">
          <h5>{props.pooldemand}</h5>
        </div>
        <div className="poolstart poolbox">{activated(props.activated)}</div>
      </div>
    </Fragment>
  );
};

export default Pool;
