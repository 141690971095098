import React, { Fragment, Component } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./assets/Dashboard.css";
import { isVisible } from "@testing-library/user-event/dist/utils";
import {
  faTimeline,
  faCommentsDollar,
  faUsers,
  faHouseUser,
  faWallet,
  faLandmark,
  faCircleInfo,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

class MiniToggle extends Component {
  state = {
    isVisible: false,
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };

  logout = () => {
    function deleteCookies(name) {
      document.cookie =
        name + "=;expires=thu, 01 jan 1970 00:00:00 UTC;path=/;";
    }

    deleteCookies("auth");
    return (window.location.href = "https://wvsslegal.com/clientLogin.html");
  };

  handleClick = () => {
    toast.error("Restricted");
  };
  render() {
    return (
      <Fragment>
        {isVisible && (
          <div className="minimenu">
            <ul>
              <NavLink to={"./"}>
                {" "}
                <li>
                  {" "}
                  <FontAwesomeIcon className="icon" icon={faHouseUser} />
                  Home
                </li>
              </NavLink>
              <NavLink to={"wallet"}>
                {" "}
                <li>
                  <FontAwesomeIcon className="icon" icon={faWallet} />
                  Wallet
                </li>
              </NavLink>
              <NavLink to={"earnings"}>
                {" "}
                <li>
                  <FontAwesomeIcon className="icon" icon={faCommentsDollar} />
                  Earnings
                </li>
              </NavLink>
              <NavLink onClick={this.handleClick}>
                {" "}
                <li>
                  <FontAwesomeIcon className="icon" icon={faLandmark} />
                  Loans
                </li>
              </NavLink>
              <NavLink to={"history"}>
                {" "}
                <li>
                  {" "}
                  <FontAwesomeIcon className="icon" icon={faTimeline} />
                  History
                </li>{" "}
              </NavLink>
              <NavLink to={"user"}>
                {" "}
                <li>
                  <FontAwesomeIcon className="icon" icon={faUsers} />
                  User
                </li>
              </NavLink>
              <NavLink to={"help"}>
                {" "}
                <li>
                  <FontAwesomeIcon className="icon" icon={faCircleInfo} />
                  Help
                </li>
              </NavLink>
              <NavLink onClick={this.logout}>
                {" "}
                <li>
                  <FontAwesomeIcon className="icon" icon={faRightFromBracket} />
                  Logout
                </li>
              </NavLink>
            </ul>
          </div>
        )}
      </Fragment>
    );
  }
}

export default MiniToggle;
