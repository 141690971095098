import React, { Component, Fragment } from "react";
import "./assets/wallet.css";
import WalletBalance from "./WalletBalance";
import Balance from "./Balance";
import MiniTransactionHistory from "./MiniTransactionHistory";
import DigitalAssets from "./DigitalAssets";
import Bonus from "./Bonus";
import StakedAsset from "./StakedAsset";

class Wallet extends Component {
  render() {
    return (
      <Fragment>
        <div className="gridcontainer">
          <div className="boxes boxa">
            <Balance />
          </div>
          <div className="boxes boxb">
            <MiniTransactionHistory />
          </div>
          <div className="boxes boxc">
            <DigitalAssets />
          </div>
          <div className="boxes boxd">
            <WalletBalance />
          </div>
          <div className="boxes boxe">
            <StakedAsset />
          </div>
          <div className="boxes boxf">
            <Bonus />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Wallet;
