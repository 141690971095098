import React, { Component, Fragment } from "react";
import "../components/assets/nav.css";
import ProfileCard from "./ProfileCard";
import NamePlate from "./NamePlate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import UserData from "./services/User";

class NavBar1 extends Component {
  constructor(props) {
    super(props);
    this.clickRef = React.createRef();
    this.state = { isVisible: false };
  }

  toggleMenu = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };

  handleOutsideClick = (event) => {
    if (
      this.clickRef.current &&
      !this.clickRef.current.contains(event.target)
    ) {
      this.setState({
        isVisible: false,
      });
    }
  };

  componentDidMount() {
    window.addEventListener("click", this.handleOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleOutsideClick);
  }

  render() {
    return (
      <Fragment>
        <nav
          id="navi"
          className="navbar bg-body-tertiary navbar-expand-lg1 bg-primary navi"
          data-bs-theme="dark"
          style={{ backgroundColor: "#e3f2fd" }}
          ref={this.clickRef}>
          <form className="d-flex" role="search">
            <input
              className="form-control me-2 inputstyle"
              id="inputstyle"
              type="search"
              placeholder="Type to search"
              aria-label="Search"
            />
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </form>

          <div id="imgpack1" className="imgpack">
            <NamePlate name={UserData.name} />
            <img
              src={require("../utils/images/testimonial-avatar.jpg")}
              height={"100%"}
              width={"100%"}
              alt=""
              onClick={this.toggleMenu}
            />
          </div>

          {this.state.isVisible && <ProfileCard />}

          <FontAwesomeIcon
            icon={faCaretDown}
            onClick={this.toggleMenu}
            className="downarrow"
            style={{ cursor: "pointer" }}
          />
        </nav>
      </Fragment>
    );
  }
}

export default NavBar1;
