import React, { Component } from "react";
import "./assets/aside.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimeline,
  faCommentsDollar,
  faUsers,
  faHouseUser,
  faWallet,
  faLandmark,
  faCircleInfo,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

class Aside extends Component {
  state = {};
  handleClick = () => {
    toast.error("Initialize Wallet to unlock");
  };
  render() {
    const style = { margin: 0 };
    return (
      <React.Fragment>
        <ul className="listgroup" id="asidelist">
          <NavLink className="link" to="/">
            <li className="menu-btn list-group-item">
              <FontAwesomeIcon className="icon" icon={faHouseUser} />
              Dashboard
            </li>{" "}
          </NavLink>
          <NavLink className="link" to="wallet">
            <li style={style} className="menu-btn list-group-item">
              {" "}
              <FontAwesomeIcon className="icon" icon={faWallet} />
              Wallet
            </li>
          </NavLink>
          <NavLink className="link" to="earnings">
            <li style={style} className="menu-btn list-group-item ">
              <FontAwesomeIcon className="icon" icon={faCommentsDollar} />
              Earnings
            </li>
          </NavLink>
          <NavLink className="link" onClick={this.handleClick}>
            <li style={style} className="menu-btn list-group-item ">
              <FontAwesomeIcon className="icon" icon={faLandmark} />
              Loans/Mortgages
            </li>{" "}
          </NavLink>
          <NavLink className="link" to="history">
            <li style={style} className="menu-btn list-group-item">
              <FontAwesomeIcon className="icon" icon={faTimeline} />
              History
            </li>
          </NavLink>
          <NavLink className="link" to="user">
            <li style={style} className="menu-btn list-group-item">
              <FontAwesomeIcon className="icon" icon={faUsers} />
              User
            </li>
          </NavLink>{" "}
          <NavLink className="link" to="desk">
            <li style={style} className="menu-btn list-group-item">
              <FontAwesomeIcon className="icon" icon={faDesktop} />
              Digital Desk
            </li>
          </NavLink>
          <NavLink className="link" to="help">
            <li style={style} className="menu-btn list-group-item">
              <FontAwesomeIcon className="icon" icon={faCircleInfo} />
              Help
            </li>
          </NavLink>
        </ul>
      </React.Fragment>
    );
  }
}

export default Aside;
